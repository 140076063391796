<template>
  <FormBuilder
    :type="'form'"
    @createdForm="createdForm"
  />
</template>

<script>
import FormBuilder from '@/views/components/form-builder/FormBuilder.vue'

export default {
  components: {
    FormBuilder,
  },
  methods: {
    createdForm() {
      this.$router.push({ name: 'admin-form-folders' })
    },
  },
}
</script>
